import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import BikeNav from "../components/BikeNav"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Exercise Air Bike Reviews for 2020 - AirBikeReview"
      description="Get expert advice on the best air bikes in the market today, and find out which options best suit your budget, body type, fitness goals, and workout space."
    />
    <Hero />
    <BikeNav />
  </Layout>
)

export default IndexPage
