import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const BikeSecton = styled.section`
  box-sizing: inherit;
  display: block;
  overflow: hidden;
  padding: 3rem 0;
  padding-top: 5rem !important;
  border: none;
  background-color: #f5f5f5;
  .bike__container {
    font-size: 16px;
    box-sizing: inherit;
    margin: 0 auto;
    max-width: 84.875rem;
    padding: 0;
    header {
      font-size: 16px;
      box-sizing: inherit;
      display: block;
      max-width: 54rem;
      margin: 0 auto;
      padding: 0;
      padding-bottom: 2rem !important;
      text-align: center !important;
    }
    h2 {
      font-size: 3.125rem;
      max-width: 54rem;
      margin: 0 auto;
      padding: 0;
      padding-bottom: 2rem !important;
      text-align: center !important;
    }
    p {
      margin: 0;
      font-weight: 400;
      line-height: 1.333;
      letter-spacing: -0.2px;
      margin-top: 0;
      margin-bottom: 1rem;
      font-size: 1.25rem;
    }
    a {
      -webkit-font-smoothing: antialiased;
      text-align: center !important;
      box-sizing: inherit;
      background: 0 0;
      color: #1264a3;
      cursor: pointer;
      text-decoration: none;
      word-break: break-word;
      font-weight: 400;
      line-height: 1.44444444;
      letter-spacing: normal;
      font-size: 1.125rem;
      display: inline-block;
      margin-bottom: 0;
      position: relative;
      border-bottom: none;
    }
  }
  .block__grid {
    box-sizing: inherit;
    margin-bottom: 1rem;
    list-style-type: none;
    display: grid;
    grid-gap: 1rem 4.43902439%;
    justify-content: center;
    padding-top: 0;
    margin-right: 5rem !important;
    margin-left: 5rem !important;
    justify-items: center !important;
    grid-template-columns: repeat(4, 1fr);
    a {
      list-style-type: none;
      box-sizing: inherit;
      cursor: pointer;
      text-decoration: none;
      word-break: break-word;
      width: 100%;
      max-width: 400px;
      margin: 15px auto;
      color: inherit;
      background: #fff;
      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
      text-align: center;
      display: block;
      padding: 10px 10px 35px;
      border-bottom: none;
      position: relative;
      z-index: 1;
      break-inside: avoid;
      flex-direction: column;
      transition: transform 420ms cubic-bezier(0.165, 0.84, 0.44, 1);
      margin-bottom: 2.43902439%;
      margin-left: 0;
      flex-basis: 23.17%;
      margin-right: 0;
      figure {
        list-style-type: none;
        cursor: pointer;
        word-break: break-word;
        color: inherit;
        text-align: center;
        box-sizing: inherit;
        display: block;
        margin: 0;
        padding: 0;
        .bike__image {
          list-style-type: none;
          cursor: pointer;
          word-break: break-word;
          color: inherit;
          text-align: center;
          box-sizing: inherit;
          border: 0;
          max-width: 100%;
          height: auto;
          margin-bottom: 20px;
        }
        .bike__logo {
          list-style-type: none;
          cursor: pointer;
          word-break: break-word;
          color: inherit;
          text-align: center;
          box-sizing: inherit;
          border: 0;
          max-width: 55%;
          height: auto;
          transition: filter 420ms cubic-bezier(0.165, 0.84, 0.44, 1);
          filter: grayscale(100%);
          margin-bottom: 20px;
        }
      }
      .bike__copy {
        list-style-type: none;
        cursor: pointer;
        word-break: break-word;
        color: inherit;
        text-align: center;
        box-sizing: inherit;

        font-weight: 400;
        line-height: 1.44444444;
        letter-spacing: normal;
        font-size: 1rem;
        margin: 0;
        margin-bottom: 1rem;
      }
    }
    a:hover {
      box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
      transform: scale(1.05);
      z-index: 2;
      opacity: 1;
      visibility: visible;
    }
  }
`

const BikeNav = () => (
  <BikeSecton>
    <div className="bike__container">
      <header>
        <h2>Our Top Rated Air Bikes</h2>
        <p>Check out our best rated Air Bikes for 2020</p>
        <p>
          <Link to="/top-rated-air-bikes-2020">
            See all of our top rated picks
          </Link>
        </p>
      </header>
      <div className="block__grid">
        <Link to="/rogue-echo-bike-review">
          <figure>
            <img
              className="bike__image"
              src="/images/rogue-echo-bike.jpg"
              alt="rogue echo bike review"
            />
          </figure>
          <figure>
            <img
              className="bike__logo"
              src="/images/logo/rogue.png"
              alt="rogue fitness"
            />
          </figure>
          <p className="bike__copy">
            The Rogue Echo Bike combines heavy-duty steel, precision
            engineering, and convenient customization to forge a stronger,
            sturdier fan bike.
          </p>
        </Link>

        <Link to="assault-airbike-review">
          <figure>
            <img
              className="bike__image"
              src="/images/assault-airbike.jpg"
              alt="Assault Airbike Review"
            />
          </figure>
          <figure>
            <img
              className="bike__logo"
              src="/images/logo/assault_fitness.png"
              alt="Assault Airbike"
            />
          </figure>
          <p className="bike__copy">
            The Assault AirBike reinvents and retools nearly every component of
            the traditional fan bike, from the frame construction to the crank,
            pedals, monitor and more.
          </p>
        </Link>

        <Link to="/schwinn-airdyne-pro-review">
          <figure>
            <img
              width="85%"
              className="bike__image"
              src="/images/schwinn-airdyne-ad7.png"
              alt="Schwinn AirDyne Pro review"
            />
          </figure>
          <figure>
            <img
              className="bike__logo"
              src="/images/logo/Schwinn.png"
              alt="Schwinn AirDyne"
            />
          </figure>
          <p className="bike__copy">
            The Schwinn Airdyne AD Pro has been manufacturing quality bikes for
            over a century, and in the fitness world, they’ve been a leading
            innovator in air resistance technology.
          </p>
        </Link>

        <Link to="/concept2-bike-erg-review">
          <figure>
            <img
              className="bike__image"
              src="/images/bike-erg.jpg"
              alt="Concept 2 Bike Erg"
            />
          </figure>
          <figure>
            <img
              className="bike__logo"
              src="/images/logo/concept2.png"
              alt="concept 2"
            />
          </figure>
          <p className="bike__copy">
            The BikeErg is the latest innovation from the makers of the Concept2
            Indoor Rower and SkiErg. The design is built around precision
            flywheel air resistance, creating a workout customized to any
            athlete who uses it.
          </p>
        </Link>
      </div>
    </div>
  </BikeSecton>
)

export default BikeNav
