import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const HeroSecton = styled.section`
  padding-top: 150px;
  position: relative;
  min-height: 0;
  padding-bottom: 2rem;
  padding-top: 70px;
  .hero__content {
    font-size: 16px;
    color: #1d1d1d;
    box-sizing: inherit;
    max-width: 76.875rem;
    padding: 0;
    margin: 0 auto;
    position: relative;
    text-align: center;
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
    header {
      text-align: center;
      box-sizing: inherit;
      display: block;
      width: 100%;
      color: #1d1d1d;

      .wrap__medium {
        margin: 0 auto;
        padding: 0;
        max-width: 54rem;
        h1 {
          font-weight: 700;
          line-height: 1.12;
          letter-spacing: -1.5px;
          margin-top: 0;
          margin-bottom: 1rem;
          font-size: 3.5rem;
          max-width: 40rem;
          margin-left: auto;
          margin-right: auto;
        }
        p {
          margin: 0;
          font-weight: 400;
          line-height: 1.333;
          letter-spacing: -0.2px;
          margin-top: 0;
          margin-bottom: 1rem;
          font-size: 1.25rem;
          width: 550px;
          margin: auto;
        }
      }
    }
  }
  .header__btn {
    margin-top: 40px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    height: 50px;
    vertical-align: middle;
    border-width: 0;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    -webkit-appearance: none;
    cursor: pointer;
    outline-style: none;
    transition-property: all;
    user-select: none;
    white-space: nowrap;
    border-radius: 4px;
    font-size: 16px;
    line-height: 50px;
    transition-duration: 80ms;
    transition-timing-function: linear;
    box-sizing: border-box;
    min-width: 40px;
    background-color: #2db67d;
    box-shadow: inset 0 0 0 1px #e0e6ed;
    color: #fff;
    padding: 0 20px;
    :hover {
      box-shadow: inset 0 -2px 0 rgba(31, 45, 61, 0.15);
      background-color: #2db67d;
    }
  }
`

const Hero = () => (
  <HeroSecton>
    <div className="hero__content">
      <header>
        <div className="wrap__medium">
          <h1>Looking For The Perfect Exercise Air Bike?</h1>
          <p>
            Get expert advice on the best air bikes in the market today, and
            find out which options best suit your budget, body type, fitness
            goals, and workout space.
          </p>
          <Link to="/top-rated-air-bikes-2020" className="header__btn">
            See Our Favorite Choices
          </Link>
        </div>
      </header>
    </div>
  </HeroSecton>
)

export default Hero
